import { hashMapFactory } from "@/utils/helper";
import { courses } from "@/utils/inputDataStandards";
import { authGet } from "@/utils/CandidaturasAPI";

export const getCourse = (courseId) => {
  let coursesObj = hashMapFactory(courses);
  return coursesObj[courseId].nome;
};

export const getProgramByCourse = (courseId) => {
  let coursesObj = hashMapFactory(courses);
  return coursesObj[courseId].grauacademico;
};

export const getCandidates = async () => {
  let r = await authGet("/candidatura");
  r.data;
  let candidates = [];
  r.data.forEach((data) => {
    let selectedCourses = data.inscricao_curso_estudante[0];
    let validatedCourse = selectedCourses.cursoId_validado;
    let payload = {
      id: data.id,
      name: data.nome,
      candidateCode: data.codigo_candidato,
      contact: data.contato,
      sosContact: data.contato_emergencia,
      course1: getCourse(selectedCourses.cursoId_escolhido[0]),
      course2: getCourse(selectedCourses.cursoId_escolhido[1]),
      courses: [
        {
          id: selectedCourses.cursoId_escolhido[0],
          name: getCourse(selectedCourses.cursoId_escolhido[0]),
        },
        {
          id: selectedCourses.cursoId_escolhido[1],
          name: getCourse(selectedCourses.cursoId_escolhido[1]),
        },
      ],
      program: getProgramByCourse(selectedCourses.cursoId_escolhido[0]),
      regime: data.periodo_inscricaoId === 1 ? "Laboral" : "Pós Laboral",
      hasPayed:
        data.Comprovativos.length >= 3 ? hasPayed(data.Comprovativos) : false,
      proofs: hashMapFactory(data.Comprovativos, "tipocomprovativo"),
      isAproved: validatedCourse !== null,
    };
    candidates.push(payload);
  });
  return candidates;
};

export const hasPayed = (payload) => {
  let proofs = hashMapFactory(payload, "tipocomprovativo");
  return proofs.scannerPagamento !== null;
};
