<template>
  <nav
    class="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50"
  >
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex justify-start items-center">
        <button
          v-if="isLoggedIn && canShowDrawer"
          data-drawer-target="drawer-navigation"
          data-drawer-toggle="drawer-navigation"
          aria-controls="drawer-navigation"
          class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            aria-hidden="true"
            class="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Toggle sidebar</span>
        </button>
        <router-link
          :to="{ name: 'home' }"
          class="flex items-center justify-between mr-4"
        >
          <img
            src="/img/icons/LogoUnipiaget.webp"
            class="mr-3 h-8"
            alt="Unipiaget Logo"
          />
          <span
            class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
          ></span>
        </router-link>
      </div>
      <div
        v-if="isLoggedIn && canShowDrawer"
        class="flex items-center lg:order-2"
      >
        <!-- Notifications -->
        <button
          type="button"
          class="p-2 mr-1 text-s-color rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        >
          <span class="sr-only">View notifications</span>
          <!-- Bell icon -->
          <svg
            aria-hidden="true"
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          class="flex mx-3 text-sm bg-gray-200 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          id="user-menu-button"
          aria-expanded="false"
          data-dropdown-toggle="usermenu"
        >
          <span class="sr-only">Open user menu</span>
          <img
            class="w-8 h-8 rounded-full"
            src="/img/icons/user-icon.png"
            alt="user photo"
          />
        </button>

        <!-- User Menu -->
        <div
          class="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
          id="usermenu"
        >
          <div class="py-3 px-4">
            <span
              class="block text-sm font-semibold text-gray-900 dark:text-white"
              >{{ nameFactory }}
            </span>
            <span class="block text-sm text-gray-900 truncate dark:text-white">
              {{ userData.nameRole }}
            </span>
          </div>
          <ul
            class="py-1 text-gray-700 dark:text-gray-300"
            aria-labelledby="usermenu"
          >
            <li>
              <a
                href="#"
                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                >Meu Perfil</a
              >
            </li>
          </ul>
          <ul
            class="py-1 text-gray-700 dark:text-gray-300"
            aria-labelledby="usermenu"
          >
            <li>
              <span
                class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                @click="logout"
                >Sair
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="w-16 lg:w-2/6 flex justify-end"
        v-else-if="canShowLoginBtn && canShowDrawer"
      >
        <action-button text="Entrar" :is-main-btn="true" @click="login" />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ActionButton from "@/components/form/ActionButton";
import { showDrawer } from "@/utils/dashboardData";
import { LOGOUT } from "@/store";
export default {
  name: "NavComponent",
  components: {
    ActionButton,
  },
  computed: {
    ...mapState(["isLoggedIn", "userData"]),
    nameFactory() {
      if (this.userData.nome === "") return this.userData.nameRole;
      return `${this.userData.nome.split(" ")[0]} ${this.userData.apelido}`;
    },
  },
  data() {
    return {
      canShowLoginBtn: false,
      canShowDrawer: false,
    };
  },
  methods: {
    ...mapMutations([LOGOUT]),
    login() {
      return this.$router.push({ name: "login" });
    },
    logout() {
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken !== "") localStorage.removeItem("refreshToken");
      this.LOGOUT();
      return this.$router.push({ name: "login" });
    },
  },
  watch: {
    $route(to) {
      this.canShowLoginBtn =
        to.name === "home" || to.name === "register" || to.name === "thankYou";
      this.canShowDrawer = showDrawer(to.name);
    },
  },
};
</script>

<style scoped></style>
