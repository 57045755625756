<template>
  <button type="button" :class="mainBtnClass">
    <slot name="icon-left" />
    {{ text }}
    <slot name="icon-right" />
  </button>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    isMainBtn: {
      required: false,
      default: false,
    },
    defaultWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      mainBtn: this.isMainBtn,
    };
  },
  computed: {
    mainBtnClass() {
      return {
        mainBtn: this.mainBtn,
        secondaryBtn: !this.mainBtn,
        applyDefaultWidth: this.defaultWidth,
      };
    },
  },
};
</script>

<style scoped>
button {
  @apply flex items-center justify-center text-white rounded-lg font-medium text-sm px-3.5 py-2 md:px-5 md:py-2.5 focus:ring-4 focus:outline-none dark:bg-p-color dark:hover:bg-s-color dark:focus:ring-s-color;
}
button:disabled {
  @apply bg-gray-500 text-gray-300 focus:bg-gray-700 focus:cursor-not-allowed opacity-50;
}
.mainBtn {
  @apply bg-p-color hover:bg-s-color focus:ring-s-color;
}
.secondaryBtn {
  @apply bg-gray-300 text-gray-900 border-gray-200 hover:bg-gray-200 hover:text-s-color focus:z-10 focus:ring-gray-100;
}
.applyDefaultWidth {
  @apply w-full lg:w-1/4;
}
</style>
