export const detectDeviceType = () => {
  const ua = navigator.userAgent;

  // Device type determination using User-Agent and viewport width
  if (/android|iphone|windows phone|blackberry|iemobile|opera mini/i.test(ua)) {
    return 1; // Mobile
  } else if (
    /ipad|tablet|playbook|silk/i.test(ua) ||
    (window.innerWidth >= 600 && window.innerWidth <= 1024)
  ) {
    return 2; // Tablet
  } else {
    return 3; // Desktop
  }
};

export const searchObjectArray = (arr, key, item) => {
  arr.forEach((a) => {
    if (a[key] === item) return a;
  });
  return null;
};

export const searchArray = (arr, item) => {
  arr.forEach((a) => {
    if (a === item) return a;
  });
  return null;
};

/**
 *
 * @param payload
 * @param field
 * @returns {{}}
 */
export const hashMapFactory = (payload, field = "id") => {
  let hashMap = {};
  payload.forEach((eachData) => {
    hashMap[eachData[field]] = eachData;
  });
  return hashMap;
};

export const removeInMap = (hashMap, id) => {
  delete hashMap[id];
  let list = [];
  let items = Object.entries(hashMap);

  items.forEach((item) => {
    list.push(item[1]);
  });

  return { list, hashMap };
};

export const isTokenExpired = (token) => {
  try {
    // Decode the token payload
    const payload = JSON.parse(atob(token.split(".")[1]));

    // Get the current time in seconds since the epoch
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    return currentTime > payload.exp;
  } catch (ex) {
    return true; // Treat as expired if token can't be parsed
  }
};

export const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, "0"); // Ensures 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = String(date.getFullYear()).slice(-2); // Last 2 digits of the year

  return `${day} - ${month} - 20${year}`;
};
export const rawTimeCalcultor = (target) => {
  const today = new Date(); // Get current date and time
  const targetDate = new Date(target); // Define the target date

  // Calculate the time difference in milliseconds
  return targetDate.getTime() - today.getTime();
};

export const timeDifCalculator = (target) => {
  let timeDif = rawTimeCalcultor(target);
  if (timeDif > 0) {
    // Convert milliseconds to seconds
    var secondsDiff = Math.ceil(timeDif / 1000);

    // Calculate days, hours, minutes, and seconds
    let days = Math.floor(secondsDiff / (3600 * 24));
    let hours = Math.floor((secondsDiff % (3600 * 24)) / 3600);
    let minutes = Math.floor((secondsDiff % 3600) / 60);
    let seconds = secondsDiff % 60;
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
};
