<template>
  <li>
    <router-link
      class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
      :to="to"
    >
      <slot name="icon"></slot>
      <span class="ml-3">{{ name }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "NavLink",
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      required: true,
    },
  },
};
</script>

<style scoped></style>
