<template>
  <aside
    class="fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidenav"
    id="drawer-navigation"
  >
    <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
      <form action="#" method="GET" class="md:hidden mb-2">
        <label for="sidebar-search" class="sr-only">Search</label>
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            name="search"
            id="sidebar-search"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Search"
          />
        </div>
      </form>
      <ul class="space-y-2">
        <nav-link name="Início" :to="{ name: 'dashboard' }">
          <template #icon>
            <home-icon class="w-6 h-6 text-gray-500" />
          </template>
        </nav-link>
        <nav-link
          name="Inscrição"
          :to="{ name: 'register' }"
          v-if="token === ''"
        >
          <template #icon>
            <one-more-icon class="w-6 h-6 text-gray-500" />
          </template>
        </nav-link>

        <li>
          <button
            type="button"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            aria-controls="dropdown-pages"
            data-collapse-toggle="dropdown-pages"
          >
            <doc-icon />
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Candidaturas</span
            >
            <carret-down-icon />
          </button>
          <ul id="dropdown-pages" class="hidden py-2 space-y-2">
            <li>
              <nav-link
                name="Estado"
                :class="['navLink']"
                :to="{ name: 'timeline' }"
                v-if="userData.roleId === 3"
              />
            </li>
            <li>
              <nav-link
                name="Nova"
                :class="['navLink']"
                :to="{ name: 'register' }"
                v-if="userData.roleId !== 3"
              />
            </li>
            <li>
              <nav-link
                name="Aprovadas"
                :class="['navLink']"
                :to="{ name: 'candidates', params: { status: 'aproved' } }"
                v-if="userData.roleId !== 3"
              />
            </li>
            <li>
              <nav-link
                name="Pendentes"
                :class="['navLink']"
                :to="{ name: 'candidates', params: { status: 'pending' } }"
                v-if="userData.roleId !== 3"
              />
            </li>
          </ul>
        </li>
        <li v-if="userData.roleId === 1">
          <button
            type="button"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            aria-controls="colaborator-pages"
            data-collapse-toggle="colaborator-pages"
          >
            <user-plus-icon />
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Funcionários</span
            >
            <carret-down-icon />
          </button>
          <ul id="colaborator-pages" class="hidden py-2 space-y-2">
            <li>
              <nav-link
                name="Cadastrar"
                :class="['navLink']"
                :to="{ name: 'timeline' }"
                v-if="userData.roleId === 1"
              />
            </li>
            <li>
              <nav-link
                name="Listar"
                :class="['navLink']"
                :to="{ name: 'register' }"
                v-if="userData.roleId === 1"
              />
            </li>
          </ul>
        </li>
        <li v-if="userData.roleId === 1">
          <button
            type="button"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            aria-controls="users-pages"
            data-collapse-toggle="users-pages"
          >
            <users-icon />
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Usuários</span
            >
            <carret-down-icon />
          </button>
          <ul id="users-pages" class="hidden py-2 space-y-2">
            <li>
              <nav-link
                name="Cadastrar"
                :class="['navLink']"
                :to="{ name: 'timeline' }"
              />
            </li>
            <li>
              <nav-link
                name="Listar"
                :class="['navLink']"
                :to="{ name: 'register' }"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import NavLink from "@/components/nav/NavLink";
import HomeIcon from "@/components/SvgIcons/HomeIcon";
import OneMoreIcon from "@/components/SvgIcons/OneMoreIcon";
import { mapState } from "vuex";
import DocIcon from "@/components/SvgIcons/DocIcon";
import CarretDownIcon from "@/components/SvgIcons/CarretDownIcon";
import UsersIcon from "@/components/SvgIcons/UsersIcon";
import UserPlusIcon from "@/components/SvgIcons/UserPlusIcon";
export default {
  name: "AsideComponent",
  components: {
    UserPlusIcon,
    UsersIcon,
    CarretDownIcon,
    DocIcon,
    NavLink,
    HomeIcon,
    OneMoreIcon,
  },
  computed: {
    ...mapState(["userData", "token"]),
    isActive() {
      return { active: true };
    },
  },
};
</script>

<style scoped>
.active {
  @apply bg-s-color text-gray-50;
}
.navLink {
  @apply flex items-center p-1 pl-5 w-full text-base font-medium rounded-lg transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700;
}
</style>
