<template>
  <loading-spinner v-if="isLoading" />
  <div class="antialiased bg-gray-100 dark:bg-gray-900">
    <nav-component />
    <aside-component v-if="isLoggedIn && canShowDrawer" />
    <main :class="canShowAside">
      <router-view />
    </main>
  </div>
</template>

<script>
import NavComponent from "@/components/nav/NavComponent";
import AsideComponent from "@/components/nav/AsideComponent";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import { isTokenExpired } from "@/utils/helper";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  DONE_LOADING,
  LOGIN_USER,
  FETCH_PERIOD,
  FETCH_PROVINCES,
  FILTER_CAT_COURSES,
  SET_LOADING,
} from "@/store";
import { showDrawer } from "@/utils/dashboardData";
import { initFlowbite } from "flowbite";

export default {
  name: "App",
  components: {
    LoadingSpinner,
    NavComponent,
    AsideComponent,
  },
  computed: {
    ...mapState(["isLoggedIn", "isLoading", "userData", "currentPeriod"]),
    canShowAside() {
      return { renderAside: this.isLoggedIn };
    },
  },

  data() {
    return {
      token: null,
      canShowDrawer: false,
    };
  },

  methods: {
    ...mapMutations([
      LOGIN_USER,
      FILTER_CAT_COURSES,
      SET_LOADING,
      DONE_LOADING,
    ]),
    ...mapActions([FETCH_PROVINCES, FETCH_PERIOD]),
    tokenExpired() {
      this.token = localStorage.getItem("refreshToken");
      return isTokenExpired(this.token);
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      import("flowbite").then(() => {
        initFlowbite();
      });
    });
    this.FETCH_PERIOD()
      .then(() => {
        if (!this.tokenExpired()) {
          this.LOGIN_USER(this.token);
        }
        this.FILTER_CAT_COURSES();
        this.FETCH_PROVINCES();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  },

  watch: {
    $route(to) {
      this.canShowDrawer = showDrawer(to.name);
      this.$nextTick(() => {
        import("flowbite").then(() => {
          initFlowbite();
        });
      });
    },
  },
};
</script>

<style scoped>
main {
  @apply p-4 h-auto pt-20;
}
.renderAside {
  @apply md:ml-64;
}
</style>
