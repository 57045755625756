import axios from "axios";
export const getCourses = async () => {
  return await axios.get(`${process.env.VUE_APP_BASE_URL}/comum/cursos`);
};

export const getProvinces = async () => {
  return await axios.get(`${process.env.VUE_APP_BASE_URL}/comum/provincia`);
};

export const getDocTypes = async () => {
  return await axios.get(
    `${process.env.VUE_APP_BASE_URL}/comum/tipodocumentos`
  );
};

export const getDistricts = async (countryId) => {
  return await axios.get(
    `${process.env.VUE_APP_BASE_URL}/comum/distrito/provincia/${countryId}`
  );
};

export const storeFormData = async (uri, formData) => {
  return await axios.post(`${process.env.VUE_APP_BASE_URL}${uri}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const patchFormData = async (uri, formData) => {
  return await axios.patch(`${process.env.VUE_APP_BASE_URL}${uri}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
    },
  });
};
export const patchData = async (uri, data) => {
  return await axios.patch(`${process.env.VUE_APP_BASE_URL}${uri}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
    },
  });
};

export const login = async (data) => {
  return await axios.post(`${process.env.VUE_APP_BASE_URL}/auth/signin`, data);
};

export const sendMail = async (mailStructure) => {
  return await axios.post(
    `${process.env.VUE_APP_BASE_URL}/comum/sendmail`,
    mailStructure,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const authGet = async (uri) => {
  return await axios.get(`${process.env.VUE_APP_BASE_URL}${uri}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
    },
  });
};
